import { Component, OnDestroy, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../../_services/setting.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html'
})
export class GuidesComponent implements OnInit {

  private _baseGuideAccess: boolean = false;

  constructor(public languageService: LanguageService, private settingService: SettingService, private dataService: DataService, public permissionService: PermissionService, private router: Router, private route: ActivatedRoute
  ) {

  }

  public get baseGuideAccess() {
    return this._baseGuideAccess;
  }

  ngOnInit() {
    this._baseGuideAccess = this.permissionService.permissions.BaseGuideAccess || this.permissionService.user.IsSuper;

  }


  


}

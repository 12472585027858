import { Directive, Input, OnChanges, ElementRef } from '@angular/core';

import { DataService } from '../_services/data.service';

@Directive({
  selector: '[sweImage]'
})
export class ImageDirective implements OnChanges {

  @Input() imageFolder: string;
  @Input() imageFile: string;
  @Input() imageWidth: string;
  @Input() imageHeight: string;
  @Input() basicAuth: boolean;

  constructor(private dataService: DataService, private element: ElementRef) {
    
  }


  ngOnChanges() {
    if (this.imageFile) {

      /*Crop********/
      let crop = '';
      let width = this.imageWidth;
      let height = this.imageHeight;
      if (typeof width != "undefined" && width.length > 0 && typeof height != "undefined" && height.length > 0) {
        crop = '/' + width + '/' + height;
      }
      /*************/

      let folder = '';
      if (typeof this.imageFolder != "undefined" && this.imageFolder.length > 0) {
        folder = this.imageFolder + '/';
      }

      this.dataService.imageRequest('/api/v1/files/' + folder + this.imageFile + crop, this.basicAuth)
        .subscribe(res => {

          let reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = () => {

            this.element.nativeElement.src = reader.result;
          }

        });

    }
  }
}

<swe-card [header]="languageService.getItem(134)" [open]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweHasNews']">
  <span swe-rightcommand class="bg-success text-white rounded bi-swe-pull-right" *ngIf="permissionService.permissions.News>1"><i class="bi bi-plus-lg bi-swe-fw swe-click" (click)="goto(0)"></i></span>
  <swe-adminrule [rulelist]="['SweNewsLevels']"></swe-adminrule>
  <div class="list-group">
    <ng-container *ngFor="let item of news">
      <div class="list-group-item" [ngClass]="{'list-group-item-warning': item.NotPublished,'list-group-item-danger': item.Expired}" *ngIf="(showpublic || !item.IsPublic)">
        <!--List mode-->
        <div class="d-flex flex-wrap" *ngIf="!item.isedit && item.Id>0 && (showpublic || !item.IsPublic)"
             [ngClass]="{'swe-click': (item.Access>1||(permissionService.permissions.NewsAll>0&&item.Levels.length==0))}"
             (click)="(item.Access>1||(permissionService.permissions.NewsAll>0&&item.Levels.length==0))&&goto(item.Id)">
          <div class="col-12 col-lg-2">
            <img class="swe-circle-image" alt="{{item.Title}}" *ngIf="item.Image.length>0" sweImage imageFolder="announcementimages" [basicAuth]="true" [imageFile]="item.Image" imageWidth="60" imageHeight="60" />
          </div>
          <div class="col-12 col-lg-10 d-flex flex-wrap">
            <div class="col-12 col-md-6"><b>{{item.Title}}</b></div>
            <div class="col-12 col-md-6"><i><span *ngIf="item.Role">({{item.Role}})&nbsp;</span><span class="me-1" *ngFor="let level of item.Levels | slice:0:3;">{{level.LevelName}}</span><span *ngIf="item.Levels.length > 3">...</span></i></div>
            <div class="col-12 col-md-12" [innerHTML]="wash(item.Body)"></div>
            <div class="col-12 col-md-12" *ngIf="item.Link.length>0"><a class="text-primary swe-click" (click)="openLink(item, $event)">{{item.LinkName}}</a></div>
            <div class="col-12 col-md-12" *ngIf="permissionService.permissions.ShowNewsPublishInfo"><i>{{dateTimeService.format(item.Publish)}}</i></div>
          </div>
        </div>
      </div>
    </ng-container>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>

import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { DataService } from '../../_services/data.service';
import { SwPush } from '@angular/service-worker';
import { deleteRange } from 'quill/modules/keyboard';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'swe-usersubscriptions',
  templateUrl: './usersubscriptions.component.html'
})
export class UserSubscriptionsComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _requestgroups: any[] = [];
  private _isloading: boolean = false;
  private _subscriptions: any[] = [];
  private _currentClientSubscription: any = null; 

  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private swPush: SwPush
  ) {
    
  }

  ngOnInit() {
    this._requestgroups = [];
    this.dataService.tokenRequest('/api/v1/requests/groups', 'GET', {})
      .subscribe((res) => {
        this._requestgroups = res;
      });
  }
  ngOnChanges() {
    
  }


  /*Properties*/
  public get isloading() {
    return this._isloading;
  }
  public get subscriptions() {
    return this._subscriptions;
  }
  public get requestgroups() {
    return this._requestgroups;
  }


  /*Methods*/
  toggleSubscribeRequestgroup(group, e) {
    e.stopPropagation();
    if (group.Access < 2 && !group.Subscribe) {
      return;
    }
    group.Subscribe = !group.Subscribe;

    this.dataService.tokenRequest('/api/v1/requests/groups/subscription/' + this.id, 'PUT', group, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });

        }
      });
  }
  public load() {

    if (this.swPush.isEnabled) {

      this._currentClientSubscription = null;

      this.swPush.subscription.subscribe((sub) => {

        if (sub) { this._currentClientSubscription = sub; }

        this._isloading = true;

        this._subscriptions = [];
        this.dataService.tokenRequest('/api/v1/PushNotification/subscriptions', 'GET')
          .subscribe((res) => {

            if (res) {
              this._subscriptions = res;
            }

            this._isloading = false;

          });
      });
    }
  }
  public getName(subscription) {
    let pos = subscription.endpoint.lastIndexOf('/');
    if (pos > 0) {
      return subscription.endpoint.substring(0, pos + 1);
    }
    else if (subscription.endpoint.length > 40) {
      return subscription.endpoint.substring(0, 40);
    }
    else {
      return subscription.endpoint;
    }
  }
  public isCurrent(subscription) {
    return (this._currentClientSubscription && this._currentClientSubscription.endpoint == subscription.endpoint); 
  }
  public delete(subscription) {

    this.dataService.tokenRequest('/api/v1/PushNotification/unsubscribe/', 'POST', subscription, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          this.load();
        }
      });

  }



  /*Functions*/
  


}

<swe-pageheader></swe-pageheader>
<div class="row justify-content-md-center py-2">
  <div class="col-12 col-md-3">
    <swe-card header="{{header||languageService.getItem(1045)}}" [hasRefresh]="false" [hasCollapse]="false" [bigheader]="true">
      <div class="mb-3" *ngIf="isDebug">
        <div><b>Debug</b></div>
        <div *ngFor="let line of debuglist">{{line}}</div>
      </div>
      <div class="mb-3">
        <img sweImage alt="Logo" title="Logo" style="width:100%;" imageFolder="images" [imageFile]="permissionService.unauthpermissions.Logo" [basicAuth]="true" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0">
        <a href="javascript:void(0);" *ngIf="!permissionService.unauthpermissions.Logo||permissionService.unauthpermissions.Logo.length==0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
      <div class="py-5">
        <div class="swe-big-header" style="text-align:center;">{{text||languageService.getItem(1046)}}</div>
        <div class="swe-big-header" style="text-align:center;" *ngIf="mode==0&&permissionService.unauthpermissions.FingerPrint">{{languageService.getItem(1051)}}</div>
        <div class="swe-big-header" style="text-align:center;">{{time}}</div>
      </div>
      <div class="d-grid gap-2 py-5" *ngIf="permissionService.unauthpermissions.FingerPrint">
        <button class="btn btn-primary swe-big-text" (click)="changemode()">{{buttontext||languageService.getItem(1052)}}</button>
      </div>
      <div class="float-end">
        <a href="javascript:void(0);" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
    </swe-card>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html'
})
export class NewsComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _news = [];
  private _loading: boolean;
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private router: Router
  ) {

    settingService.initView(4);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
        this.search();
      });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this.search();
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get more(): boolean {
    return this._more;
  }
  public get news() {
    return this._news;
  }
  public get hits() {
    return this._hits;
  }


  public handleSearchEvent($event) {
    this.search($event.getMore);
  }

  /*Methods*/
  public search(getmore: boolean = false) {
    if (this._loading) {
      return;
    }
    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._news = [];
    }

    let filter = {
      LevelList: this.settingService.levelList(3),
      OnlyParentLevelList: this.settingService.levelList(3, true),
      LevelGroups: this.settingService.level.levelgroups.join(),
      Top: this._top,
      Multiple: this._multiple,
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking') 
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/news/search', 'POST', filter)
      .subscribe(res => {

        res.News.forEach((item) => {

          if (item.Link != item.LinkName) {
            item.LinkUrl = item.Link + '|' + item.LinkName;
          }
          else {
            item.LinkUrl = item.Link;
          }

          item.TempIsPublic = item.IsPublic;

          this._news.push(item);
        });

        this._hits = this._news.length;
        this._more = res.More;

        this._loading = false;
      });
  }
  public openLink(obj, e) {

    e.stopPropagation();

    window.open(obj.Link, '_blank');
  }

  public goto(id) {

    this.router.navigate(['/admin/news', id]);

  }
  public wash(s: string) {

    let res = '';

    if (s && s.length > 0) {
      res = s.replace(/\n/g, ' ');
      if (res.length > 40) {
        res = res.substring(0, 40) + '...';
      }
    }

    return res;
  }


}

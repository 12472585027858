import { Component, OnInit, Input, EventEmitter, Output, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'swe-articledata',
  templateUrl: './articledata.component.html'
})
export class ArticleDataComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: number;
  @Output() contractdataChange = new EventEmitter<any>();

  private _article: any = {};
  private _articlecategories: any[] = [];
  private _articleTimeTypes: any[] = [];
  private _types: any[] = [];
  private _subscriptions: Subscription[] = [];
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private location: Location
  ) {
    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        if (this.permissionService.permissions.Articles > 1) {
          this.save();
        }
      })); 
    
    
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.load();
  }


  //Properties
  public get article() {
    return this._article;
  }
  public get articleTimeTypes() {
    return this._articleTimeTypes;
  }
  public get articlecategories() {
    return this._articlecategories;
  }
  public get types() {
    return this._types;
  }
  


  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/articles/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._article = res;
      });

  }
  public save(copy: boolean = false) {

    let verb = 'Post';
    let path = '/api/v1/articles/'
    if (this.id > 0 && !copy) {
      verb = 'Put';
      path += this.id;
    }
    if (copy) {
      this._article.Name += ` - ${this.languageService.getItem(325)}`
    }
    if (this._article.Value == null) {
      this._article.Value = 0;
    }
    if (this._article.Price == null) {
      this._article.Price = 0;
    }
    if (this._article.Sort == null) {
      this._article.Sort = 0;
    }
    this.dataService.tokenRequest(path, verb, this._article, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
        }

        this.load();

      });

  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/articles/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  };
  



  //Functions
  private init() {

    //Types
    this._articlecategories = [];
    this._articlecategories.push({ Id: 0, Name: this.languageService.getItem(1295) });
    this._articlecategories.push({ Id: 1, Name: this.languageService.getItem(1296) });

    //TimeTypes
    this._articleTimeTypes = [];
    this._articleTimeTypes.push({ Id: 0, Name: '' });
    this._articleTimeTypes.push({ Id: 1, Name: this.languageService.getItem(1504) });
    this._articleTimeTypes.push({ Id: 2, Name: this.languageService.getItem(383) });

    //Types
    this._types = [];
    this._types.push({ Id: 1, Name: this.languageService.getItem(1004) });
    this._types.push({ Id: 2, Name: this.languageService.getItem(1005) });

  }

}

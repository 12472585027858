import { Component, Input, EventEmitter, Output, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'swe-confirmpassword',
  templateUrl: './confirmpassword.component.html',
})
export class ConfirmPasswordComponent implements OnInit, OnChanges {
  @Input() userId = 0;
  @Input() description = null;
  @Input() isvisible: boolean = false;
  @Input() event: any = null;
  @Input() isButton: boolean = false;
  @ViewChild('btnAccept') focusElement: ElementRef;

  private _visible: boolean = false;
  private _passwordObj: any = {};
  constructor(public languageService: LanguageService, private alertService: AlertService, private dataService: DataService) {

  }

  public set passwordObj(val) {
    this._passwordObj = val;
  } 
  public get passwordObj() {
    return this._passwordObj;
  }

  ngOnInit() {


    
  }

  ngOnChanges() {
    if (this._visible != this.isvisible) {
      this._visible = this.isvisible;

      if (this._visible) {
        this.manageFocus();
      }
    }
  }


  //Properties
  public get visible() {
    return this._visible;
  }

  public set visible(val) {
    this._visible = val;
  }


  //Methods
  public open(e) {
    e.stopPropagation();

    this._visible = true;
    this.manageFocus();
  }
   
  public close(e) {
    e.stopPropagation();
    this._visible = false;
  }
  public accept(e) {
    if (this._passwordObj.Password != this._passwordObj.ConfirmPassword) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(448) })
      return;
    }
    e.stopPropagation();

    this.dataService.tokenRequest('/api/v1/users/' + this.userId + '/password', 'PUT', this._passwordObj, 'text', 'response').subscribe(res => {
      if (res) {
        let type = 'success';
        if (res.body != this.languageService.getItem(1512)) {
          type = 'warning';
        }
        this.alertService.Add({ message: res.body, type });
        if (type == 'success') {
          this._visible = false;
        }
      }
    });
  }
  public decline(e) {
    this._passwordObj = {};
    e.stopPropagation();
    this._visible = false;
  }



  //Functions
  private manageFocus() {
    setTimeout(() => {
      if (this.focusElement) {
        let element = this.focusElement.nativeElement;
        element.focus();
  
      }
    }, 0); //Create a macrotask that will run in the next VM turn
  }

}

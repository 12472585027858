<div [ngClass]="{'d-grid gap-2': isButton, 'd-inline': !isButton}" (click)="open($event)">
  <!-- Trigger modal -->
  <ng-content>
  </ng-content>
</div>

<!-- Modal -->
<div class="modal fade" (click)="decline($event)" style="font-size:initial; font-weight:initial;" [ngClass]="{'d-block show': visible}">
  <div class="modal-dialog modal-dialog-centered" (click)="$event.stopPropagation();">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{languageService.getItem(8)}}</h5>
      </div>
      <div class="modal-body">
        <p *ngIf="description">{{description}}</p>
        <swe-element type="System.Password" [statusLabel]="2" [label]="languageService.getItem(445)" [(model)]="passwordObj.Password"></swe-element>
        <swe-element type="System.Password" [statusLabel]="2" [label]="languageService.getItem(446)" [(model)]="passwordObj.ConfirmPassword"></swe-element>
      </div>
      <div class="modal-footer">
        <ng-content select="[extraOptions]"></ng-content>
        <button type="button" class="btn btn-secondary" (click)="decline($event)">Avbryt</button>
        <button type="button" class="btn btn-primary" (click)="accept($event)" #btnAccept>{{languageService.getItem(16)}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" (click)="decline($event)" *ngIf="visible"></div>

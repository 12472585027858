import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html'
})
export class MessagesComponent implements OnInit, OnDestroy {
  private unsubsribe$ = new Subject<void>();
  private _messages = [];
  private _more: boolean = false;
  private _all: boolean = false;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _maxrows: number = 0;
  private _loading: boolean = false;
  private _addmenu: boolean = false;
  private _start: string;
  private _end: string;
  private _addMenu: boolean = false;
  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private router: Router
  ) {

    settingService.initView(2 | 128 | 512, [], [{ type: 'user', key: 'roles' }, { type: 'user', key: 'activity' }]);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._start = this.settingService.start('message');
    this._end = this.settingService.end('message');
    this.search();
  }

  /*Properties*/
  public get addMenu() {
    return this._addMenu;
  }
  public set addMenu(val) {
    this._addMenu = val;
  }
  public get messages() {
    return this._messages;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get more(): boolean {
    return this._more;
  }
  public get all(): boolean {
    return this._all;
  }
  public get hits(): number {
    return this._hits;
  }
  public get maxRows(): number {
    return this._maxrows;
  }
  public get addmenu(): boolean {
    return this._addmenu;
  }

  public get start(): string {
    return this._start;
  }
  public get end() {
    return this._end;
  }

  public handleSearchEvent($event) {
    this._start = this.settingService.start('message');
    this._end = this.settingService.end('message');
    this.search($event.getMore, $event.getAll);
  }

  /*Methods*/
  public search(getmore: boolean = false, all: boolean = false) {
    if (this._loading) {
      return;
    }
    this._more = false;
    this._all = false;

    let top = 25;
    if (typeof all != "undefined" && all) {
      top = 0;
    }

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._messages = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      //Messages
      Start: this._start,
      End: this._end,
      Address: this.settingService.message.address,
      Subject: this.settingService.message.subject,
      Body: this.settingService.message.body,
      Types: this.settingService.message.messagetype,
      Status: this.settingService.message.messagestatus,
      //Users
      UserSearch: this.settingService.user.search,
      UserList: this.settingService.userList(),
      LevelGroups: this.settingService.user.levelgroups.join(),
      LevelList: this.settingService.levelList(1),
      OnlyParentLevelList: this.settingService.levelList(1, true)
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/messages/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Messages.forEach(message => {
            this._messages.push(message);
          });

          this._hits = this._messages.length;
          this._more = res.More;
          this._all = (res.More && this._messages.length > 0);

          this._loading = false;
        }
      });
  }
  public open(id: number, categoryId: number, e) {

    let category = 'outgoing/';
    if (categoryId != null && typeof categoryId != "undefined") {
      if (categoryId == 2) {
        category = 'incoming/';
      }
      else if (categoryId == 3) {
        category = 'reply/';
      }
    }

    let url = '/messages/' + category + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public deleteAccess(message) {

    return message.StatusCode != 6 && new Date(message.SendTime) > new Date();
  }
  public delete(message, e) {

    e.stopPropagation();

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/messages/' + message.Id, 'Delete', {})
      .subscribe(res => {

        this.search(false);

        this._loading = false;
      });
  }

  public add(e) {
    
    if (this.permissionService.permissions.Email > 0 && this.permissionService.permissions.Sms > 0) {
      e.stopPropagation();
      this._addmenu = !this._addmenu;
    }
    else if (this.permissionService.permissions.Email > 0) {
      this.send(1, e);
    }
    else if (this.permissionService.permissions.Sms > 0) {
      this.send(3, e);
    }
  }
  public send(type, e) {
    if (type == 1) {
      this.router.navigate(['/messages/send/email']);
    }
    else if (type == 3) {
      this.router.navigate(['/messages/send/sms']);
    }
  }
}

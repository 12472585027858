<swe-pageheader [header]="languageService.getItem(1390)" *ngIf="permissionService.permissions.GuideAccess > 0"></swe-pageheader>
<swe-filteritems></swe-filteritems>
<div class="row py-2" *ngIf="permissionService.permissions.GuideAccess > 0">
  <div class="col-12">
    <swe-guidelist></swe-guidelist>
  </div>
  <div class="col-12" *ngIf="permissionService.permissions.BaseGuideAccess||permissionService.user.IsSuper">
    <swe-guidelist [systemAdmin]="true"></swe-guidelist>
  </div>
</div>

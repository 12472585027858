import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingService } from '../../_services/setting.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
  selector: 'swe-guidelist',
  templateUrl: './guidelist.component.html'
})
export class GuideListComponent implements OnInit, OnDestroy {

  @Input() systemAdmin: boolean = false;
  private unsubsribe$ = new Subject<void>();
  private _isLoading: boolean = false;
  private _guides: any[] = [];
  private _more: boolean = false;
  private _hits: number = 0;
  private _multiple: number = 1;
  private _top: number = 25;

  public get guides() {
    return this._guides;
  }
  public get isLoading() {
    return this._isLoading;
  }
  public get more() {
    return this._more;
  }
  public get hits() {
    return this._hits;
  }

  public get header() {
    return this.systemAdmin ? this.languageService.getItem(1510) : this.languageService.getItem(1511);
  }
  constructor(public languageService: LanguageService, private settingService: SettingService, private dataService: DataService, public permissionService: PermissionService, private router: Router, private route: ActivatedRoute
  ) {
    settingService.initView(1024, ['Guide']);
    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
        this.load();
      });

  }

  ngOnInit() {

      this.load();
 

  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  public goto(id: number) {
    if (this.systemAdmin) {
      this.router.navigate(['/systemadmin/guides', id]);
      return;
    }
    this.router.navigate(['/admin/guides', id]);
  }
  public loadBaseGuides(more: boolean = false) {
    this._more = false;

    if (more) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._guides = [];
    }
    this._isLoading = true;
    const filter = {
      top: this._top,
      multiple: this._multiple,
      ...this.settingService.guide
    };

    this.dataService.tokenRequest('/api/v1/guides/base/search', 'POST', filter).subscribe((res) => {
      if (res) {

        res.Guides.forEach((g) => {
          this._guides.push(g);
        });
        this._more = res.More;
        this._hits = this._guides.length;
      }
      this._isLoading = false;
    });
  }

  public loadInternalGuides(more: boolean = false) {
    this._more = false;

    if (more) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._guides = [];
    }
    this._isLoading = true;
    const filter = {
      top: this._top,
      multiple: this._multiple,
      ...this.settingService.guide
    };

    this.dataService.tokenRequest('/api/v1/guides/search', 'POST', filter).subscribe((res) => {
      if (res) {

        res.Guides.forEach((g) => {
          this._guides.push(g);
        });
        this._more = res.More;
        this._hits = this._guides.length;
      }
      this._isLoading = false;
    });
  }

  public load(more: boolean = false) {
    if (this._isLoading) {
      return;
    }
    if (this.permissionService.permissions.GuideAccess == 0) {
      return;
    }
    if (this.systemAdmin) {
      this.loadBaseGuides(more);
      return;
    }
    this.loadInternalGuides(more);
  }


}

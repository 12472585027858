<nav class="navbar navbar-dark">
  <div class="container-fluid">
    <div class="navbar-brand">Filtrering&nbsp;<i class="bi bi-pin-fill bi-swe-inverse d-none d-md-inline-block swe-click" [ngClass]="{'bi-swe-rotate-90':!settingService.pinned}" (click)="settingService.pinned=!settingService.pinned"></i></div>
    <div class="navbar-text ms-auto">
      <span class="badge rounded-pill bg-secondary swe-badge swe-click me-3" (click)="manageUserFilter(0)" [attr.title]="languageService.getItem(974)"><i class="bi bi-swe-fw bi-arrow-counterclockwise"></i></span>
    </div>
    <button type="button" class="navbar-toggler" (click)="menuService.closeSearch()" #sweFocusElement><i class="bi bi-x-lg bi-swe-topmenu"></i></button> 
    <div class="w-100 mt-2">
      <!--Filters-->
      <swe-card header="{{languageService.getItem(991)}}" *ngIf="settingService.isVisible(512)" [hasRefresh]="false" [open]="true">
        <div class="input-group">
          <select class="form-control form-select" [(ngModel)]="userfilterid" (ngModelChange)="manageUserFilter($event)" [attr.aria-label]="languageService.getItem(991)">
            <option *ngFor="let item of userfilters" [value]="item.Id">{{item.OldName}}</option>
          </select>
          <button class="btn btn-swe" (click)="showYourFilter=!showYourFilter" *ngIf="userfilterid!=0"><i class="bi bi-pencil-square"></i></button>
        </div>
        <div class="input-group mt-1" *ngIf="showYourFilter&&userfilterid!=0">
          <input type="text" class="form-control" [attr.aria-label]="languageService.getItem(991)" [(ngModel)]="userfilter.Name" [placeholder]="languageService.getItem(993)" />
          <button class="btn btn-primary" (click)="saveUserFilter()" [attr.title]="languageService.getItem(16)"><i class="bi bi-floppy2-fill"></i></button>
          <button class="btn btn-danger" (click)="deleteUserFilter()" [attr.title]="languageService.getItem(17)" *ngIf="userfilterid>0"><i class="bi bi-trash-fill"></i></button>
        </div>
      </swe-card>
      <!--Bookings-->
      <swe-card header="{{languageService.getItem(4)}}" *ngIf="settingService.isVisible(1)" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetBooking($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(817)}}" type="booking" key="intervaltype" *ngIf="!settingService.isHidden('booking', 'intervaltype')">
          <swe-element [type]="'System.RadioList'" [items]="intervalTypeList" [label]="languageService.getItem(817)" [(model)]="settingService.booking.intervaltype"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(933)}}" type="booking" key="weekdays">
          <swe-element [type]="'System.MultiList'" [items]="weekdayList" [label]="languageService.getItem(933)" [(model)]="settingService.booking.weekdays"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(15)}}" type="booking" key="status" *ngIf="!permissionService.permissions.HideStatus">
          <swe-element [type]="'System.MultiList'" [items]="generalService.bookingstatus" [label]="languageService.getItem(15)" [(model)]="settingService.booking.status" [disabled]="permissionService.isLocked(3)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(12)}}" type="booking" key="personal">
          <swe-element [type]="'System.RadioList'" [items]="personalList" [label]="languageService.getItem(12)" [(model)]="settingService.booking.personal" [disabled]="permissionService.isLocked(4)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(699)}}" type="booking" key="shifttype" *ngIf="permissionService.permissions.EmploymentPlan>0&&!settingService.isHidden('booking', 'shifttype')">
          <swe-element [type]="'System.RadioList'" [items]="shiftTypeList" [label]="languageService.getItem(699)" [(model)]="settingService.booking.shifttype" [disabled]="permissionService.isLocked(7)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(160)}}" type="booking" key="reservation" *ngIf="!settingService.isHidden('booking', 'reservation')">
          <swe-element [type]="'System.RadioList'" [items]="reservationList" [label]="languageService.getItem(160)" [(model)]="settingService.booking.reservation" [disabled]="permissionService.isLocked(5)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(545)}}" type="booking" key="replaceableasnoslot" *ngIf="permissionService.permissions.Replaceable&&!settingService.isHidden('booking', 'replaceableasnoslot')">
          <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(545)" [(model)]="settingService.booking.replaceableasnoslot"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(72)}}" type="booking" key="reservation" *ngIf="permissionService.permissions.CheckIn>0">
          <swe-element [type]="'System.RadioList'" [items]="checkInList" [label]="languageService.getItem(72)" [(model)]="settingService.booking.checkin" [disabled]="permissionService.isLocked(6)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(475)}}" type="booking" key="bookingtype" *ngIf="permissionService.permissions.Replaceable||(permissionService.permissions.Standby>0)||(permissionService.permissions.Abscent>0)||(permissionService.permissions.Decline>0)">
          <swe-element [type]="'System.RadioList'" [items]="excludeEmptyShiftOptions" [label]="languageService.getItem(699)" [(model)]="settingService.booking.excludeemptyshiftbookingtype" [disabled]="permissionService.isLocked(7)"></swe-element>
          <swe-element [type]="'System.MultiList'" [items]="bookingTypeList" [label]="languageService.getItem(475)" [(model)]="settingService.booking.bookingtype"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(654)}}" type="booking" key="owner" *ngIf="permissionService.permissions.Owner>0&&!settingService.isHidden('booking', 'owner')">
          <swe-usersearch [bottomMargin]="3" [onlyone]="true" [(model)]="settingService.booking.owner" [type]="'User'"></swe-usersearch>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(395)}}" type="booking" key="activitytypes" *ngIf="permissionService.permissions.Activity>0">
          <swe-element [type]="'System.RadioList'" [items]="activityOptionList" [label]="languageService.getItem(395)" [(model)]="settingService.booking.activityoption" *ngIf="!settingService.isHidden('booking', 'activityoption')"></swe-element>
          <swe-element [type]="'System.MultiList'" [items]="generalService.activitytypes" [label]="languageService.getItem(395)" [(model)]="settingService.booking.activitytypes"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(1121)}}" type="booking" key="timetypes" *ngIf="timeTypeList.length>0">
          <swe-element [type]="'System.MultiList'" [items]="timeTypeList" [label]="languageService.getItem(1121)" [(model)]="settingService.booking.timetypes"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(298)}}" type="booking" key="levelgroups">
          <swe-element [type]="'System.MultiList'" [items]="generalService.levelgroupswithmissing" [label]="languageService.getItem(298)" [(model)]="settingService.booking.levelgroups"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(14)}}" type="booking" key="levels">
          <swe-levelsearch [fetchChildren]="false" [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event,2)" [label]="languageService.getItem(14)" [chosen]="settingService.booking.levels"></swe-levelsearch>
          <div class="list-group pt-0" *ngIf="settingService.booking.levels.length>0">
            <div class="list-group-item" *ngFor="let level of settingService.booking.levels">
              <i class="bi bi-swe me-1 swe-click" [ngClass]="{'bi-check-square': level.active, 'bi-square': !level.active}" (click)="toggleLevel(level)"></i>
              <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<span *ngIf="level.OnlyParent">&nbsp;<i [attr.title]="languageService.getItem(149)" class="bi bi-swe-fw bi-diagram-3-fill"></i></span>
              <i class="bi bi-swe bi-x-lg bi-swe-pull-right swe-click" (click)="removeLevel(level, 2)"></i>
            </div>
          </div>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Users-->
      <swe-card header="{{languageService.getItem(2)}}" *ngIf="settingService.isVisible(2)" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetUser($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(59)}}" type="user" key="users|search" *ngIf="!settingService.isHidden('user', 'search')">
          <swe-usersearch [bottomMargin]="3" [onlyone]="false" (modelChange)="addUser($event)" [label]="languageService.getItem(59)" [chosen]="settingService.user.users" [hasUserSearch]="true" [disabled]="permissionService.isLocked(21)"></swe-usersearch>
          <div class="list-group pt-0 pb-1" *ngIf="settingService.user.users.length>0||settingService.user.search.length>0">
            <div class="list-group-item" *ngIf="settingService.user.search.length>0">
              <span style="font-style:italic;">...{{settingService.user.search}}...</span>
              <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="settingService.user.search=''"></i>
            </div>
            <div class="list-group-item" *ngFor="let user of settingService.user.users">
              <i class="bi bi-swe me-1 swe-click" [ngClass]="{'bi-check-square': user.active, 'bi-square': !user.active}" (click)="toggleUser(user)"></i>
              <span>{{user.Firstname}} {{user.Lastname}} ({{user.Username}})</span>
              <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="removeUser(user)"></i>
            </div>
          </div>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(82)}}" type="user" key="roles" *ngIf="!settingService.isHidden('user', 'roles')">
          <swe-element [type]="'System.MultiList'" [items]="generalService.roles" [label]="languageService.getItem(82)" [(model)]="settingService.user.roles" [disabled]="permissionService.isLocked(22)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(231)}}" type="user" key="activity" *ngIf="!settingService.isHidden('user', 'activity')">
          <swe-element [type]="'System.RadioList'" [items]="activityList" [label]="languageService.getItem(231)" [(model)]="settingService.user.activity" [disabled]="permissionService.isLocked(23)"></swe-element>
        </swe-filtercard>
        <div *ngIf="settingService.isVisible(8)">
          <swe-filtercard header="{{languageService.getItem(173)}}" type="user" key="availability">
            <swe-element [type]="'System.RadioList'" [items]="availabilityList" [label]="languageService.getItem(173)" [(model)]="settingService.user.availability" [disabled]="permissionService.isLocked(13)"></swe-element>
          </swe-filtercard>
          <swe-filtercard header="{{languageService.getItem(728)}}" type="user" key="availabilitylist">
            <swe-element [type]="'System.MultiList'" [items]="generalService.markeddatetypes" [label]="languageService.getItem(728)" [(model)]="settingService.user.availabilitylist"></swe-element>
          </swe-filtercard>
        </div>
        <swe-filtercard header="{{languageService.getItem(4)}}" type="user" key="bookingtype" *ngIf="!settingService.isHidden('user', 'bookingtype')">
          <swe-element [type]="'System.RadioList'" [items]="bookingUserTypeList" [label]="languageService.getItem(4)" [(model)]="settingService.user.bookingtype" [disabled]="permissionService.isLocked(14)"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(905)}}" type="user" key="employment|employmentcategories" *ngIf="permissionService.permissions.Employment&&!settingService.isHidden('user', 'employment')">
          <swe-element [type]="'System.RadioList'" [items]="employmentList" [label]="languageService.getItem(905)" [(model)]="settingService.user.employment" (modelChange)="settingService.user.employmentcategories=[]"></swe-element>
          <swe-element [type]="'System.FilteredMultiple'" [items]="employmentCategoryList" [label]="languageService.getItem(1060)" [container]="{min: 4, max: 8}" [model]="settingService.user.employment != 1 ? [] : settingService.user.employmentcategories" (modelChange)="settingService.user.employment != 1 ? [] : settingService.user.employmentcategories = $event" [disabled]="settingService.user.employment != 1" *ngIf="menuService.showSearch"></swe-element>
        </swe-filtercard>
        <!--<swe-filtercard header="{{languageService.getItem(1274)}}" type="user" key="frameworkcontracts" *ngIf="permissionService.permissions.UserContract&&generalService.frameworkcontracts.length>0">
    <swe-element [type]="'System.FilteredMultiple'" [items]="frameworkcontractsList" [label]="languageService.getItem(1274)" [container]="{min: 4, max: 8}" [(model)]="settingService.user.frameworkcontracts" *ngIf="menuService.showSearch">
    </swe-element>
  </swe-filtercard>-->
        <swe-filtercard header="{{languageService.getItem(953)}}" type="user" key="contract" *ngIf="permissionService.permissions.UserContract">
          <swe-element [type]="'System.RadioList'" [items]="contractList" [label]="languageService.getItem(953)" [(model)]="settingService.user.contract" (modelChange)="settingService.user.contracts=[]"></swe-element>
          <swe-element [type]="'System.FilteredMultiple'" [items]="contractsList" [label]="languageService.getItem(953)" [container]="{min: 4, max: 8}" [(model)]="settingService.user.contracts" [disabled]="settingService.user.contract != 1" *ngIf="menuService.showSearch">
          </swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(298)}}" type="user" key="levelgroups" *ngIf="!settingService.isHidden('user', 'levelgroups')">
          <swe-element [type]="'System.MultiList'" [items]="generalService.levelgroupswithmissing" [label]="languageService.getItem(298)" [(model)]="settingService.user.levelgroups"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(14)}}" type="user" key="levels" *ngIf="!settingService.isHidden('user', 'levels')">
          <swe-levelsearch [fetchChildren]="false" [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event,1)" [label]="languageService.getItem(14)" [chosen]="settingService.user.levels"></swe-levelsearch>
          <div class="list-group pt-0" *ngIf="settingService.user.levels.length>0">
            <div class="list-group-item" *ngFor="let level of settingService.user.levels">
              <i class="bi bi-swe me-1 swe-click" [ngClass]="{'bi-check-square': level.active, 'bi-square': !level.active}" (click)="toggleLevel(level)"></i>
              <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<span *ngIf="level.OnlyParent">&nbsp;<i [attr.title]="languageService.getItem(149)" class="bi bi-swe-fw bi-diagram-3-fill"></i></span>
              <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="removeLevel(level, 1)"></i>
            </div>
          </div>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Levels-->
      <swe-card header="{{languageService.getItem(3)}}" *ngIf="settingService.isVisible(4)" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetLevel($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(1274)}}" type="level" key="frameworkcontracts" *ngIf="permissionService.permissions.SweLevelContracts>0&&generalService.frameworkcontracts.length>0">
          <swe-element [type]="'System.FilteredMultiple'" [items]="frameworkcontractsList" [label]="languageService.getItem(1274)" [container]="{min: 4, max: 8}" [(model)]="settingService.level.frameworkcontracts" *ngIf="menuService.showSearch">
          </swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(953)}}" type="level" key="contract" *ngIf="permissionService.permissions.SweLevelContracts>0">
          <swe-element [type]="'System.RadioList'" [items]="contractList" [label]="languageService.getItem(953)" [(model)]="settingService.level.contract" (modelChange)="settingService.levels.contracts=[]"></swe-element>
          <swe-element [type]="'System.FilteredMultiple'" [items]="contractsList" [label]="languageService.getItem(953)" [container]="{min: 4, max: 8}" [(model)]="settingService.level.contracts" [disabled]="settingService.level.contract != 1" *ngIf="menuService.showSearch"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(298)}}" type="level" key="levelgroups">
          <swe-element [type]="'System.MultiList'" [items]="generalService.levelgroupswithmissing" [label]="languageService.getItem(298)" [(model)]="settingService.level.levelgroups"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(14)}}" type="level" key="levels">
          <swe-levelsearch [fetchChildren]="false" [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event,3)" [label]="languageService.getItem(14)" [chosen]="settingService.level.levels"></swe-levelsearch>
          <div class="list-group pt-0" *ngIf="settingService.level.levels.length>0">
            <div class="list-group-item" *ngFor="let level of settingService.level.levels">
              <i class="bi bi-swe me-1 swe-click" [ngClass]="{'bi-check-square': level.active, 'bi-square': !level.active}" (click)="toggleLevel(level)"></i>
              <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<span *ngIf="level.OnlyParent" >&nbsp;<i [attr.title]="languageService.getItem(149)" class="bi bi-swe-fw bi-diagram-3-fill"></i></span>
              <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="removeLevel(level, 3)"></i>
            </div>
          </div>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Articles-->
      <swe-card header="{{languageService.getItem(1234)}}" *ngIf="settingService.isVisible(2048)&&permissionService.permissions.Articles>0">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetArticle($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(613)}}" type="article" key="type">
          <swe-element [type]="'System.RadioList'" [items]="articleTypeList" [label]="languageService.getItem(613)" [(model)]="settingService.article.type" *ngIf="menuService.showSearch">
          </swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(1234)}}" type="article" key="articles" *ngIf="permissionService.permissions.Articles>0">
          <swe-element [type]="'System.FilteredMultiple'" [items]="articlesList" [label]="languageService.getItem(1234)" [container]="{min: 4, max: 8}" [(model)]="settingService.article.articles" *ngIf="menuService.showSearch">
          </swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(1508)}}" type="article" key="timetype">
          <swe-element [type]="'System.RadioList'" [items]="articleTimeTypeList" [label]="languageService.getItem(1121)" [(model)]="settingService.article.timetype" *ngIf="menuService.showSearch">
          </swe-element>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Guides-->
      <swe-card header="{{languageService.getItem(1390)}}" *ngIf="settingService.isVisible(1024)">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetGuide($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(1079)}}" type="guide" key="search" *ngIf="!settingService.isHidden('guide', 'search')">
          <swe-element [type]="'System.Text'" [label]="languageService.getItem(82)" [(model)]="settingService.guide.search"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(1)}}" type="guide" key="keywords" *ngIf="!settingService.isHidden('guide', 'keywords')">
          <swe-element [type]="'System.MultiList'" [items]="generalService.guideKeywords" [container]="{max:6}" [label]="languageService.getItem(82)" [(model)]="settingService.guide.keywords"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(82)}}" type="guide" key="roles" *ngIf="!settingService.isHidden('guide', 'roles')">
          <swe-element [type]="'System.MultiList'" [items]="generalService.roles" [label]="languageService.getItem(82)" [(model)]="settingService.guide.roles"></swe-element>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Properties-->
      <swe-card header="{{languageService.getItem(156)}}" *ngIf="settingService.isVisible(16)&&properties.length>0" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetProperty($event)"></i></span>
        <swe-properties [label]="languageService.getItem(156)" [items]="settingService.property.properties" [properties]="properties" [showOptions]="true"></swe-properties>
        <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(539)" [statusLabel]="2" [(model)]="settingService.property.useor" *ngIf="settingService.property.properties.length>0"></swe-element>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Messages-->
      <swe-card header="{{languageService.getItem(345)}}" *ngIf="settingService.isVisible(128)" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetMessage($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(375)}}" type="message" key="address">
          <swe-element [type]="'System.String'" [label]="languageService.getItem(375)" [(model)]="settingService.message.address"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(327)}}" type="message" key="subject">
          <swe-element [type]="'System.String'" [label]="languageService.getItem(327)" [(model)]="settingService.message.subject"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(328)}}" type="message" key="body">
          <swe-element [type]="'System.String'" [label]="languageService.getItem(328)" [(model)]="settingService.message.body"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(379)}}" type="message" key="messagetype">
          <swe-element [type]="'System.MultiList'" [items]="generalService.messagetypes" [label]="languageService.getItem(379)" [(model)]="settingService.message.messagetype"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(15)}}" type="message" key="messagestatus">
          <swe-element [type]="'System.MultiList'" [items]="messagestatusList" [label]="languageService.getItem(15)" [(model)]="settingService.message.messagestatus"></swe-element>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
      <!--Logs-->
      <swe-card header="{{languageService.getItem(400)}}" *ngIf="settingService.isVisible(256)" [hasRefresh]="false">
        <span swe-rightcommand><i class="bi bi-arrow-counterclockwise bi-swe-pull-right swe-click" [attr.title]="languageService.getItem(974)" (click)="settingService.resetLog($event)"></i></span>
        <swe-filtercard header="{{languageService.getItem(405)}}" type="log" key="logobject">
          <swe-element [type]="'System.MultiListAsString'" [items]="generalService.logobjects" [label]="languageService.getItem(405)" [(model)]="settingService.log.logobject"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(404)}}" type="log" key="logtype">
          <swe-element [type]="'System.MultiListAsString'" [items]="logTypeList" [label]="languageService.getItem(404)" [(model)]="settingService.log.logtype"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(805)}}" type="log" key="showauto">
          <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(805)" [(model)]="settingService.log.showauto"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(298)}}" type="level" key="levelgroups">
          <swe-element [type]="'System.MultiList'" [items]="generalService.levelgroupswithmissing" [label]="languageService.getItem(298)" [(model)]="settingService.level.levelgroups"></swe-element>
        </swe-filtercard>
        <swe-filtercard header="{{languageService.getItem(14)}}" type="level" key="levels">
          <swe-levelsearch [fetchChildren]="false" [bottomMargin]="3" [onlyone]="false" (modelChange)="addLevel($event,3)" [label]="languageService.getItem(14)" [chosen]="settingService.level.levels"></swe-levelsearch>
          <div class="list-group pt-0" *ngIf="settingService.level.levels.length>0">
            <div class="list-group-item" *ngFor="let level of settingService.level.levels">
              <i class="bi bi-swe me-1 swe-click" [ngClass]="{'bi-check-square': level.active, 'bi-square': !level.active}" (click)="toggleLevel(level)"></i>
              <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<span *ngIf="level.OnlyParent">&nbsp;<i [attr.title]="languageService.getItem(149)" class="bi bi-swe-fw bi-diagram-3-fill"></i></span>
              <i class="bi bi-swe bi-x bi-swe-pull-right swe-click" (click)="removeLevel(level, 3)"></i>
            </div>
          </div>
        </swe-filtercard>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="refresh()" [disabled]="settingService.isrefreshing">{{languageService.getItem(13)}}</button>
        </div>
      </swe-card>
    </div>
  </div>
</nav>


import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';


@Component({
  selector: 'app-reportarchive',
  templateUrl: './reportarchive.component.html'
})
export class ReportArchiveComponent implements OnInit {

  private _groupedby: any = {};
  private _files: any[] = [];
  private _loading: boolean = false;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private listService: ListService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.load();

  }



  //Properties
  public get groupedby() {
    return this._groupedby;
  }
  public get files() {
    return this._files;
  }
  public get isloading() {
    return this._loading;
  }



  //Methods
  public download(file) {

    let filename = file.Name;

    this.dataService.imageRequest('/api/v1/files/' + file.Path + '/' + filename)
      .subscribe(res => {

        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = (e) => {

          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          let hiddenElement = document.createElement('a');
          //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
          //  //IE
          //  navigator.msSaveOrOpenBlob(res, filename);
          //}
          //else
          if (typeof hiddenElement.download != 'undefined' && !isSafari) {
            //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
            hiddenElement.href = reader.result.toString();
            hiddenElement.target = '_blank';
            hiddenElement.download = filename;

            document.body.appendChild(hiddenElement);

            hiddenElement.click();
          }
          else {
            let url = '';
            try {
              let fileArr = reader.result.toString().split(';');
              let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

              url = URL.createObjectURL(blob);
            }
            catch (e) {
              //Final solution
              let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
              url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
            }

            var popup = window.open(url, '_blank');
            if (!popup) {
              window.location.href = url;
            }
          }
        }

      });
  }
  public load() {
    if (this._loading) {
      return;
    }
    this._loading = true;

    this._files = [];
    this._groupedby = [];

    this.dataService.tokenRequest('/api/v1/reports/archive', 'GET', {})
      .subscribe((res) => {

        this._files = res;
        this._groupedby = this.listService.groupByInt(res, 'Key', undefined, 'Key'); //Folder + SubFolder

        this._loading = false;
      });
  }

  //Functions
  
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { GeneralService } from '../../_services/general.service';



@Component({
  selector: 'swe-newsdetail',
  templateUrl: './newsdetail.component.html',
})
export class NewsDetailComponent implements OnInit {

  private _id: number = 0;
  private _news: any = {};
  private _loading: boolean;
  private _items: any[];
  private _lastpublish: any;
  
  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.load();
    });
    
  }

  ngOnInit() {

    let items = [];
    if (this.permissionService.permissions.PublicNews) {
      items.push({ Id: 1, Language: 987 });
    }
    items.push({ Id: 2, Language: 988 });
    items.push({ Id: 4, Language: 989 });
    items.push({ Id: 8, Language: 742 });
    this._items = items;

  }

  /*Properties*/
  public get id() {
    return this._id;
  }
  public get loading() {
    return this._loading;
  }
  public get news() {
    return this._news;
  }
  public get items() {
    return this._items;
  }
  



  /*Methods*/
  public removeLevel(level) {
    console.log(this._news.Levels);
    let index = this._news.Levels.indexOf(level);
    if (index > -1) {
      if (this._news.Levels[index].NewsLevelId > 0) {
        this._news.Levels[index].IsDeleted = true;
      }
      else {
        this._news.Levels.splice(index, 1);
      }
    }
  }

  public addLevel(event) {
    let exists = false;
    this._news.Levels.forEach((level) => {
      if (level.LevelId == event.item.Id && !level.IsDeleted) {
        exists = true;
        return;
      }

      else if (level.LevelId == event.item.Id && level.IsDeleted) {
        exists = true;
        level.IsDeleted = false;
        return;
      }
    });
    if (exists) {
      return;
    }
    this._news.Levels.push({
      LevelId: event.item.Id,
      LevelName: event.item.Name,
      NewsLevelId: 0,
      IsDeleted: false
    });
  }
  public load() {

    this.dataService.tokenRequest('/api/v1/news/' + this._id, 'Get', {})
      .subscribe(res => {

        if (res.Link != res.LinkName) {
          res.LinkUrl = res.Link + '|' + res.LinkName;
        }
        else {
          res.LinkUrl = res.Link;
        }

        if (this._id == 0) {
          let showAt: any = this.viewCacheService.get('news_show');
          if (showAt) {
            res.ShowAt = showAt.value;
          }
        }

        this._news = res;
        this._lastpublish = this._news.Publish;
      });

      
      
  }
  public goto(obj, e) {

    e.stopPropagation();

    window.open(obj.Link, '_blank');
  }
  public add(e) {

    e.stopPropagation();

    this._news.unshift(this.newItem());
  }
  public save(obj) {
    if ((((obj.ShowAt & 2) == 2) || ((obj.ShowAt & 4) == 4)) && this.permissionService.permissions.NewsAll == 0 && obj.LevelId <= 0) {
      this.alertService.Add({ message: this.languageService.getItem(784), type: 'danger' });
      return;
    }
    else if (new Date(obj.Publish) > new Date(obj.Expires)) {
      this.alertService.Add({ message: this.languageService.getItem(812), type: 'danger' });
      return;
    }
    else if (((obj.ShowAt & 1) == 1) && (obj.RoleId > 0 || obj.LevelId > 0)) {
      this.alertService.Add({ message: this.languageService.getItem(1136), type: 'danger' });
      return;
    }

    let id = obj.Id;
    var verb = 'POST';
    var path = '/api/v1/news/'
    if (id > 0) {
      verb = 'PUT';
      path += id;
    }

    if (!obj.RoleId) { obj.RoleId = 0; }

    obj.Link = obj.LinkUrl;
    
    this.dataService.tokenRequest(path, verb, obj, 'text', 'response')
      .subscribe(response => {
    
        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
        }


        this.load();

      });
  }
  public delete(obj) {
    this.dataService.tokenRequest('/api/v1/news/' + obj.Id, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        this.location.back();

      });
  }
  public upload(file, id) {
    if (file && file.length > 0 && id > 0) {
      //Upload
      let content = file.split('|');

      let dto = {
        Name: content[0],
        Data: content[1]
      };

      this.dataService.tokenRequest('/api/v1/news/' + id + '/image', 'PUT', dto, 'text')
        .subscribe(res => {

          //Reload
          this.load();

          this.alertService.Add({ message: res, type: 'success' });
        });

    }
    else if (file == null && id > 0) {
      //Delete
      this.dataService.tokenRequest('/api/v1/news/' + id + '/image', 'DELETE', {}, 'text')
        .subscribe(res => {

          //Reload
          this.load();

          this.alertService.Add({ message: res, type: 'success' });
        });
    }
  }
  public documentcontainer(image: any) {
    return {
      clientOnly: false,
      allowedPrefix: 'png, jpg, jpeg, jfif, gif',
      folder: 'announcementimages',
      document: image,
      showimage: true,
      hidedownload: true,
      basicAuth: true
    };
  }
  public linkcontainer(linkurl) {

    if (!linkurl || linkurl.length == 0) {
      return {};
    }

    let linkarray = linkurl.split('|');

    let res: any = {
      Link: linkarray[0],
      Url: (linkarray.length == 2) ? linkarray[1] : linkarray[0]
    }

    return res;
  }
  public wash(s: string) {
    if (s && s.length > 0) {
      return s.replace(/\n/g, '<br>');
    }
    return s;
  }
  public manageExpired(e) {

    let dtLast = new Date(this._lastpublish);
    let dtPublish = new Date(this._news.Publish);
    let dtExpires = new Date(this._news.Expires);

    if (dtPublish.getTime() >= dtExpires.getTime()) {
      let diff = dtPublish.getTime() - dtLast.getTime();
      this._news.Expires = this.dateTimeService.formatWithTimeZone(new Date(dtExpires.getTime() + diff));
    }

    this._lastpublish = this._news.Publish;
  }





  //Functions
  private newItem() {
    return {
      Access: 2, //To show Save Button
      Id: 0,
      Title: '',
      Body: '',
      Publish: new Date().toDateString(),
      Expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toDateString(),
      Level: '',
      LevelId: -1, // To hide Delete Button
      IsPublic: false,
      Image: '',
      Link: '',
      LinkName: '',
      LinkUrl: '',
      isedit: true
    };
  }

}

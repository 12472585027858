import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { DataService } from '../_services/data.service';
import { PropertyService } from '../_services/property.service';
import { PermissionService } from '../_services/permission.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { AlertService } from '../_services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';



@Component({
  selector: 'app-requestsend',
  templateUrl: './requestsend.component.html'
})
export class RequestSendComponent implements OnInit  {

  private _items: any[] = [];
  private _users: any[] = [];
  private _chosenusers: any[] = [];
  private _missing: any[] = [];
  private _options: any[] = [];
  private _chosenoptions: any[] = [];
  private _group: number = 0;
  private _groupname: string = '';
  private _bookingId: number = 0;
  private _serie: number = 0;
  private _requesttype: number = 1;
  private _header: string = '';
  private _question: string = '';
  private _delayed: Date = null;
  private _usebatch: boolean = false;
  private _batchquantity: number = 0;
  private _batchdelay: number = 0;


  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private location: Location) {

    this.route.paramMap.subscribe(params => {
      if (params.get('type')) {
        this._requesttype = parseInt(params.get('type'));
      }
      if (params.get('id')) {
        this._bookingId = parseInt(params.get('id'));
      }
      if (params.get('serie')) {
        this._serie = parseInt(params.get('serie'));
      }
      if (params.get('group')) {
        this._group = parseInt(params.get('group'));
      }
      this.getBody(params.get('body'));
      this.getSubject(params.get('subject'));
    });
    
  }

  ngOnInit() {
    this.preload();
  }
  
  

/*Properties*/
  public get items() {
    return this._items;
  }
  public get users() {
    return this._users;
  }
  public get chosenusers() {
    return this._chosenusers;
  }
  public set chosenusers(val) {
    this._chosenusers = val;
  }
  public get missing() {
    return this._missing;
  }
  public get options() {
    return this._options;
  }
  public get chosenoptions() {
    return this._chosenoptions;
  }
  public set chosenoptions(val) {
    this._chosenoptions = val;
  }
  public get requesttype() {
    return this._requesttype;
  }
  public set requesttype(val) {
    this._requesttype = val;
  }
  public get groupname() {
    return this._groupname;
  }
  public get ispersonal() {
    return this._bookingId == 0 && this._group == 0;
  }
  public get header() {
    return this._header;
  }
  public set header(val) {
    this._header = val;
  }
  public get question() {
    return this._question;
  }
  public set question(val) {
    this._question = val;
  }
  public get delayed() {
    return this._delayed;
  }
  public set delayed(val) {
    this._delayed = val;
  }
  public get bookingId() {
    return this._bookingId;
  }
  public get usebatch() {
    return this._usebatch;
  }
  public set usebatch(val) {
    this._usebatch = val;
  }
  public get batchquantity() {
    return this._batchquantity;
  }
  public set batchquantity(val) {
    this._batchquantity = val;
  }
  public get batchdelay() {
    return this._batchdelay;
  }
  public set batchdelay(val) {
    this._batchdelay = val;
  }
  public get smscounter() {
    if (this._question.length > 160) {
      return Math.ceil(this._question.length / 153);
    }
    else {
      return 1;
    }
  }

  public get delayedLabel() {
    return this.permissionService.permissions.PushNotifications ? this.languageService.getItem(1253) : this.languageService.getItem(890);
  }

  /*Methods*/
  public isvisible(obj, val) {
    return (obj & val) == val;
  }
  public manageRequestType(e) {
    let type = 0;
    this._items.forEach((item) => {
      if (item.Chosen) {
        type += item.Id;
      }
    });
    this._requesttype = type;
  }
  public send() {

    if (this._requesttype == 0) {
      this.alertService.Add({ message: this.languageService.getItem(1086), type: 'danger' });
      return;
    }
    if (this._question.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(1087), type: 'danger' });
      return;
    }
    if (this._chosenusers.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(1088), type: 'danger' });
      return;
    }

    let lstUsers: any[] = [];
    this._chosenusers.forEach((u) => {
      let user = this.listService.find(this._users, 'Id', u);
      if (user) {
        lstUsers.push(user);
      }
    });

    let dto = {
      Type: this._requesttype,
      Users: lstUsers,
      Header: this._header,
      Question: this._question,
      RequestGroup: this._group,
      Booking: this._bookingId ? this._bookingId : 0,
      Serie: this._serie,
      Options: this._chosenoptions
    };

    if (this._delayed) {
      dto["SendTime"] = this._delayed;
    }

    if (this._bookingId > 0 && this._usebatch) {
      dto["BatchQuantity"] = this._batchquantity;
      dto["BatchDelay"] = this._batchdelay;
    }

    this.dataService.tokenRequest('/api/v1/requests', 'POST', dto, 'text')
      .subscribe(res => {
        this.alertService.Add({ message: res, type: 'success' });
        this.location.back();
      });

  }


  /*Functions*/
  private preload() {

    //Batch
    this._usebatch = this.permissionService.permissions.SmsBatchQuantity > 0;
    this._batchquantity = this.permissionService.permissions.SmsBatchQuantity;
    this._batchdelay = this.permissionService.permissions.SmsBatchDelayMinutes;


    //Items
    this._items.push({ Id: 1, Name: this.languageService.getItem(1082), Chosen: ((this.requesttype & 1) == 1) }); //System
    if (this.permissionService.permissions.Sms > 0) {
        this._items.push({ Id: 2, Name: this.languageService.getItem(1083), Chosen: ((this.requesttype & 2) == 2) }); //Sms
    }
    //this._items.push({ Id: 4, Name: this.languageService.getItem(1084), Chosen: ((this.requesttype & 4) == 4) }); //Email
    if (this.permissionService.permissions.PushNotifications > 0) {
        this._items.push({ Id: 8, Name: this.languageService.getItem(1085), Chosen: ((this.requesttype & 8) == 8) }); //Push
    }

    this.generalService.responseoptions.forEach((responseoptions) => {

      this._options.push({ Id: responseoptions.Key, Name: responseoptions.Value });
      if (this._bookingId > 0) {
        if (responseoptions.Extra == 'YES' || responseoptions.Extra == 'NO') {
          this._chosenoptions.push(responseoptions.Key);
        }
      }

    });

    if (this._group > 0) {
      let requestgroup = this.listService.find(this.generalService.requestgroups, 'Key', this._group);
      if (requestgroup) {
        this._groupname = requestgroup.Value;
      }
    }


    let lstUsers: any = this.viewCacheService.get('request_users');
    if (lstUsers) {
      lstUsers.value.forEach((user) => {
        let name = user.Firstname + ' ' + user.Lastname;
        if (user.Username && user.Username.length > 0) {
          name += ' (' + user.Username + ')';
        }
        this._users.push({
          Id: user.Id,
          Name: name,
          Username: user.Username,
          Firstname: user.Firstname,
          Lastname: user.Lastname,
          Email: user.Email,
          Cell: user.Cell,
          HasPush: user.HasPush
        });
        this._chosenusers.push(user.Id);
        if (this._users.some(x => !x.HasPush) && this._bookingId == 0) {
          const item = this._items.find(x => x.Id == 8);
          item.Disabled = true;
          item.Tooltip = this.languageService.getItem(1516);
        }
        if (this._users.some(x => x.Cell.length == 0) && this._bookingId == 0) {
          const item = this._items.find(x => x.Id == 2);
          item.Disabled = true;
          item.Tooltip = this.languageService.getItem(1517);
        }
        if (user.Cell.length == 0) {
          this._missing.push({ Id: 0, Name: name });
        }
      });
    }

  }
  private getBody(bodytype) {
    if (bodytype) {
      let query = '';

      //Type
      if (parseInt(bodytype) > 0) {
        query = 'type/' + bodytype + '/';
      }

      //Booking
      if (this._bookingId > 0) {
        query += 'booking/' + this._bookingId ;
      }

      //Serie
      if (this._serie > 0) {
        query += '/serie/' + this._serie;
      }

      //Get
      this.dataService.tokenRequest('/api/v1/messages/body/' + query, 'GET', {}, 'text')
        .subscribe(res => {

          this._question = res.replace(/\r/g, '');
        });
    }
  }
  private getSubject(subjecttype) {
    if (subjecttype) {
      let query = '';

      //Type
      if (parseInt(subjecttype) > 0) {
        query = 'type/' + subjecttype + '/';
      }

      //Booking
      if (this._bookingId > 0) {
        query += 'booking/' + this._bookingId;
      }

      //Get
      this.dataService.tokenRequest('/api/v1/messages/subject/' + query, 'GET', {}, 'text')
        .subscribe(res => {

          this._header = res.replace(/\r/g, '');
        });
    }
  }
}

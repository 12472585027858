import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { DataService } from '../_services/data.service';
import { SettingService, HiddenFilter } from '../_services/setting.service';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _logs = [];
  private _more: boolean = false;
  private _all: boolean = false;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;

  constructor(public languageService: LanguageService, public dateTimeService: DateTimeService, private dataService: DataService, private settingService: SettingService) {

    settingService.initView(2 | 256, [], [{ type: 'user', key: 'roles' }, { type: 'user', key: 'activity' }]);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this.search();
  }

  /*Properties*/
  public get logs() {
    return this._logs;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get more() : boolean {
    return this._more;
  }
  public get all(): boolean {
    return this._all;
  }
  public get hits(): number {
    return this._hits;
  }


  public handleSearchEvent($event) {
    this.search($event.getMore, $event.getAll);
  }

  /*Methods*/
  public search(getmore: boolean = false, all: boolean = false) {
    if (this._loading) {
      return;
    }
    this._more = false;
    this._all = false;

    let top = 25;
    if (typeof all != "undefined" && all) {
      top = 0;
    }

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._logs = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      //Logs
      Start: this.settingService.start('log'),
      End: this.settingService.end('log'),
      Objects: this.settingService.log.logobject,
      Types: this.settingService.log.logtype,
      ShowAuto: this.settingService.log.showauto,
      LevelGroups: this.settingService.level.levelgroups.join(),
      LevelList: this.settingService.levelList(3),
      OnlyParentLevelList: this.settingService.levelList(3, true),
      //Users
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      OnlyParentUserLevelList: this.settingService.levelList(1, true)
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/logs/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Logs.forEach(log => {
            this._logs.push(log);
          });

          this._hits = this._logs.length;
          this._more = res.More;
          this._all = (res.More && this._logs.length > 0);

          this._loading = false;
        }
      });
  }
  public download() {

    let filter = {
      Top: 0,
      Multiple: 1,
      //Logs
      Start: this.settingService.start('log'),
      End: this.settingService.end('log'),
      Objects: this.settingService.log.logobject,
      Types: this.settingService.log.logtype,
      ShowAuto: this.settingService.log.showauto,
      LevelGroups: this.settingService.level.levelgroups.join(),
      LevelList: this.settingService.levelList(3),
      //Users
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1)
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/logs/download', 'POST', filter, 'blob')
      .subscribe(res => {
        if (res) {

          this._loading = false;

          let now = new Date();
          let filename = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '_' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
          if (res.type == 'application/xml') {
            filename += '.xml';
          }
          else if (res.type == 'application/pdf') {
            filename += '.pdf';
          }
          else if (res.type == 'text/html') {
            filename += '.html';
          }
          else if (res.type == 'text/plain') {
            filename += '.txt';
          }
          else {
            filename += '.csv';
          }

          let reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = (e) => {

            let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            let hiddenElement = document.createElement('a');
            //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
            //  //IE
            //  navigator.msSaveOrOpenBlob(res, filename);
            //}
            //else
            if (typeof hiddenElement.download != 'undefined' && !isSafari) {
              //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
              hiddenElement.href = reader.result.toString();
              hiddenElement.target = '_blank';
              hiddenElement.download = filename;

              document.body.appendChild(hiddenElement);

              hiddenElement.click();
            }
            else {
              let url = '';
              try {
                let fileArr = reader.result.toString().split(';');
                let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

                url = URL.createObjectURL(blob);
              }
              catch (e) {
                //Final solution
                let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
                url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
              }

              var popup = window.open(url, '_blank');
              if (!popup) {
                window.location.href = url;
              }
            }
          }
          
          
        }
      });
  }

}

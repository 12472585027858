import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html'
})
export class TemplatesComponent implements OnInit {

  private _templates: any[] = [];
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private router: Router
  ) {
    //Reset
    this.settingService.initView();
  }


  ngOnInit() {

    this.load();

  }



  //Properties
  public get templates() {
    return this._templates;
  }
  public get isloading() {
    return this._loading;
  }




  //Methods
  public load() {
    if (this._loading) {
      return;
    }
    this._loading = true;

    let filter = {

    };

    this.dataService.tokenRequest('/api/v1/bookingtemplates/search', 'POST', filter)
      .subscribe((res) => {

        this._templates = res;
        this._loading = false;
      });
  }
  public goto(id) {
    this.router.navigate(['/admin/templates', id]);
  }



}

<swe-pageheader [header]="languageService.getItem(273)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [after]="after" [extra]="extra" [resetsticky]="isdragged" [datePagerOpt]="{hideTime: true, type: 'booking'}" (searchEvent)="handleSearchEvent($event)">
  <ng-template #extra>
    <!--Precision-->
    <div class="btn-group mb-1 align-top" [attr.title]="languageService.getItem(796)" [label]="languageService.getItem(796)" icon="bi-compass">
      <swe-dropdown [label]="languageService.getItem(796)" icon="bi-compass" [(isOpen)]="precisionmenu" [loading]="newprecision">
        <ul class="dropdown-menu" [ngClass]="{'show': precisionmenu }">
          <li class="dropdown-item swe-click" (click)="changePrecision(0)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==0}"></i>{{languageService.getItem(797)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(-4)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-4}"></i>{{languageService.getItem(1033)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(-3)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-3}"></i>{{languageService.getItem(1034)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(-2)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-2}"></i>{{languageService.getItem(1035)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(-1)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==-1}"></i>{{languageService.getItem(1036)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(1)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==1}"></i>{{languageService.getItem(527)}}</a></li>
          <li class="dropdown-item swe-click" (click)="changePrecision(2)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.precision==2}"></i>{{languageService.getItem(528)}}</a></li>
        </ul>
      </swe-dropdown>
    </div>
    <!--More Button-->
    <div class="btn-group ms-1 mb-1 align-top" *ngIf="more&&!dofilter">
      <button class="btn btn-primary" (click)="$event.stopPropagation();search(true)">{{languageService.getItem(57)}}</button>
    </div>
    <!--Add Button-->
    <div class="bi-swe-pull-right me-5" *ngIf="permissionService.permissions.Timeline>1||permissionService.permissions.MarkedDates>1||permissionService.permissions.News>1">
        <swe-dropdown icon="bi-plus-lg" [showLabel]="true" [(isOpen)]="addMenu" [buttonclass]="'btn-success'" [label]="languageService.getItem(100)">
          <ul class="dropdown-menu" [ngClass]="{'show': addMenu }">
            <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Timeline>1" (click)="addbooking($event)">
              <i class="bi bi-swe-fw bi-calendar-plus" [attr.title]="languageService.getItem(303)"></i>
              {{languageService.getItem(4)}}
            </li>
            <li class="dropdown-item swe-click my-1" *ngIf="permissionService.permissions.MarkedDates>1" (click)="addusermarkeddate($event)">
              <i class="bi bi-swe-fw bi-calendar-check" [attr.title]="languageService.getItem(100) + ' ' + languageService.getItem(110)"></i>
              {{languageService.getItem(110)}}
            </li>
            <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.News>1" (click)="addnews($event)">
              <i class="bi bi-swe-fw bi-newspaper" [attr.title]="languageService.getItem(100) + ' ' + languageService.getItem(131)"></i>
              {{languageService.getItem(131)}}
            </li>
          </ul>
        </swe-dropdown>
      </div>
  </ng-template>
    <ng-template #after>
    <!--Actions-->
      <div class="btn-group mb-1 align-top" *ngIf="toggleaccess">
        <div class="dropdown">
          <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': allchecked}" (click)="togglecheck($event)" *ngIf="toggleaccess" [attr.disabled]="hits > 0 ? null : true">
            <span><i class="bi bi-check-lg"></i></span>
          </button>
          <swe-dropdown icon="bi-three-dots-vertical" [stopPropagation]="true" [(isOpen)]="actionmenu" [disabled]="!isshiftchecked" [label]="languageService.getItem(843)">
            <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && isshiftchecked}">
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.CopyShift>0"><a (click)="action('copy');actionmenu=false"><i class="bi bi-swe-fw bi-journals"></i>&nbsp;{{languageService.getItem(354)}}</a></li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiShift>0"><a (click)="action('multi');actionmenu=false"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiShift>0">
                <swe-confirm (acceptChange)="action('delete');actionmenu=false" (declineChange)="actionmenu=false" [isbutton]="false" [body]="languageService.getItem(417)">
                  <i class="bi bi-swe-fw bi-trash"></i>&nbsp;{{languageService.getItem(412)}}
                </swe-confirm>
              </li>
              <li class="dropdown-divider" *ngIf="(permissionService.permissions.CopyShift>0||permissionService.permissions.MultiShift>0)&&(permissionService.permissions.ListSms>0||permissionService.permissions.BookingListSms>0)"></li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.BookingListSms>0">
                <swe-confirm [isbutton]="false" [body]="languageService.getItem(927)" (acceptChange)="send(3, 0, $event)" (declineChange)="actionmenu=false">
                  <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}
                </swe-confirm>
              </li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ListSms>0">
                <swe-confirm [isbutton]="false" [body]="languageService.getItem(928)" (acceptChange)="send(6, 0, $event)" (declineChange)="actionmenu=false">
                  <i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(178)}}
                </swe-confirm>
              </li>
              <li class="dropdown-divider" *ngIf="permissionService.permissions.Abscent>1&&groupby==0"></li>
              <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>1&&groupby==0"><a (click)="saveBooking(3);actionmenu=false;"><i class="bi bi-swe-fw bi-person-fill"></i>&nbsp;{{languageService.getItem(283)}}</a></li>
            </ul>
          </swe-dropdown>
        </div>
      </div>
      <!--Filter-->
      <div class="btn-group mb-1 align-top">
        <button [attr.aria-label]="languageService.getItem(614)" class="btn btn-swe dropdown" (click)="dofilter=!dofilter" [attr.disabled]="!ischecked ? true : null">
          <span><i class="bi bi-swe-fw bi-funnel-fill"></i></span>
        </button>
      </div>
      <!--Options-->
      <div class="btn-group ms-1 mx-md-1 mb-1 align-top">
        <div class="dropdown">
          <swe-dropdown [(isOpen)]="optionmenu" [disabled]="loading" [label]="languageService.getItem(277)">
            <ul class="dropdown-menu" [ngClass]="{'show': optionmenu }" style="position:absolute;z-index:9999;">
              <li class="dropdown-item swe-click" (click)="show(1);optionmenu=false;" *ngIf="permissionService.permissions.MarkedDates>0&&permissionService.permissions.Timeline>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(1)}"></i>{{languageService.getItem(4)}}</a></li>
              <li class="dropdown-item swe-click" (click)="show(2);optionmenu=false;" *ngIf="permissionService.permissions.MarkedDates>0&&permissionService.permissions.Timeline>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(2)}"></i>{{languageService.getItem(51)}}</a></li>
              <li class="dropdown-item swe-click" (click)="show(4);optionmenu=false;" *ngIf="permissionService.permissions.News>0"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':isChecked(4)}"></i>{{languageService.getItem(134)}}</a></li>
              <li class="dropdown-item swe-click" (click)="settingService.timeline.showemptyrows=!settingService.timeline.showemptyrows;optionmenu=false;"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.showemptyrows}"></i>{{languageService.getItem(798)}}</a></li>
              <li class="dropdown-item swe-click" (click)="settingService.timeline.includereferences=!settingService.timeline.includereferences;search(false);optionmenu=false;" *ngIf="!permissionService.permissions.HideLevels"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.includereferences}"></i>{{languageService.getItem(278)}}</a></li>
              <li class="dropdown-item swe-click" (click)="search(false);settingService.timeline.exactrendering=!settingService.timeline.exactrendering;optionmenu=false;" *ngIf="!showhour&&permissionService.permissions.ExactRendering"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.exactrendering}"></i>{{languageService.getItem(300)}}</a></li>
            </ul>
          </swe-dropdown>
        </div>
      </div>
      <div class="btn-group d-none d-md-inline-flex">
        <button class="btn btn-outline-primary" (click)="$event.stopPropagation();settingService.timeline.showgraph=!settingService.timeline.showgraph;optionmenu=false;" [ngClass]="{'btn-primary text-white':settingService.timeline.showgraph}" [attr.title]="languageService.getItem(676)"><i class="bi bi-graph-up"></i></button>
      </div>
      <div class="btn-group mx-2">
        <button class="btn  btn-outline-primary" [ngClass]="{'btn-primary text-white': !settingService.timeline.multimode && !settingService.timeline.bookingmode}" (click)="$event.stopPropagation();settingService.timeline.multimode=false;settingService.timeline.bookingmode=false" [attr.title]="languageService.getItem(1492)" *ngIf="toggleaccess||permissionService.permissions.BookingMode"><i class="bi bi-box-arrow-in-right bi-swe-fw"></i></button>
        <button class="btn btn-outline-primary" [ngClass]="{'btn-primary text-white': settingService.timeline.multimode}" (click)="manageMultiMode()" *ngIf="toggleaccess"><i class="bi bi-swe-fw bi-check-lg" [attr.title]="languageService.getItem(889)"></i></button>
        <button class="btn btn-outline-primary" [ngClass]="{'btn-primary text-white': settingService.timeline.bookingmode}" (click)="manageBookingMode()" *ngIf="permissionService.permissions.BookingMode" [attr.title]="languageService.getItem(536)"><i class="bi bi-swe-fw bi-list-ul d-none d-md-block"></i> <i class="bi bi-swe-fw bi-pencil-square d-md-none"></i></button>
      </div>
      <!--Excluded Marked (BookingMode)-->
      <div class="btn-group ms-1 mb-1 align-top" *ngIf="(marked>0)&&!dofilter">
        <button class="btn btn-swe" (click)="settingService.timeline.markedhide=!settingService.timeline.markedhide">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-check-square': settingService.timeline.markedhide, 'bi-square': !settingService.timeline.markedhide}"></i>{{languageService.getItem(683)}}</span>
        </button>
      </div>
      <!--Notifications & Requests-->
      <div class="btn-group ms-1 mb-1 align-top" *ngIf="settingService.timeline.bookingmode">
        <!--Send Request to selected users-->
        <button class="btn btn-swe btn-primary" (click)="sendRequest(false)" [attr.title]="languageService.getItem(178)" *ngIf="showRequest">
          <span><i class="bi bi-swe-fw bi-swe bi-send"></i></span>
        </button>
        <button class="btn btn-swe" (click)="sendRequest(true)" [attr.title]="languageService.getItem(196)" *ngIf="showRequest&&markedSerie>0">
          <span><i class="bi bi-swe-fw bi-swe bi-send"></i><i class="bi bi-swe-fw bi-swe bi-link-45deg"></i></span>
        </button>
        <!--Email-->
        <button class="btn btn-swe" [attr.aria-label]="languageService.getItem(920)" [attr.title]="languageService.getItem(920)" (click)="notifyemail=!notifyemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !notifyemail, 'bi-check-square': notifyemail}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i></span>
        </button>
        <!--SMS-->
        <button class="btn btn-swe" [attr.aria-label]="languageService.getItem(921)" [attr.title]="languageService.getItem(921)" (click)="notifysms=!notifysms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !notifysms, 'bi-check-square': notifysms}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-phone-fill"></i></span>
        </button>
      </div>
    </ng-template>
</swe-fixedheader>
<!--Timeline-->
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" (refreshChange)="search(false)" [stickyTop]="0" toolbarClass="mt-1" [rulelist]="['SweHasTimeline', 'SweBookingNotFilledLevels', 'SweUserTimeline', 'SweLevels', 'SweRoles', 'SweUserMarkedDates', 'SweTimelineProfile', 'SweBookingMode', 'SweAvailableUsersLevelAncestorDependency', 'SweAvailableUsersLevelDependency', 'SweAvailableUsersMark', 'SweHasNews', 'SweHasListSms']">
      <span swe-leftcommand>
        <button class="btn btn-swe" (click)="settingService.timeline.collapserow=!settingService.timeline.collapserow;search(false);" [attr.title]="languageService.getItem(1428)">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !settingService.timeline.collapserow, 'bi-check-square': settingService.timeline.collapserow}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-arrows-collapse"></i></span>
        </button>
        <button class="btn btn-swe mx-2" *ngIf="permissionService.permissions.GroupProfile" (click)="settingService.timeline.showprofile=!settingService.timeline.showprofile;search(false);" [attr.title]="languageService.getItem(409)">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !settingService.timeline.showprofile, 'bi-check-square': settingService.timeline.showprofile}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-person-lines-fill"></i></span>
        </button>
        <button class="btn btn-swe me-2 d-lg-none" (click)="menucollapsed=!menucollapsed"><i class="bi bi-swe-fw" [ngClass]="{'bi-arrow-left': !menucollapsed,'bi-arrow-right': menucollapsed}"></i></button>
      </span>
      <swe-timelinegrid (rowToggleChanged)="showRequestBtn($event)" [data]="content" [more]="more" [all]="all" [menucollapsed]="menucollapsed" [allchecked]="allchecked" [dofilter]="dofilter" [(changedSettings)]="changedSettings" [notifyemail]="notifyemail" [notifysms]="notifysms" [holidays]="holidays" (moreChange)="search(true)" (groupbyChange)="search(false, $event)" (sumoptionsChange)="search(false)" (settingsChange)="changedBySettings($event)"></swe-timelinegrid>
    </swe-card>
  </div>
</div>
<div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity:0.5; background-color:#000000;z-index:1000;" *ngIf="false"></div>

<!--Profile when Book as Abscent-->
<swe-abscentdata [buttontext]="languageService.getItem(283)" [idList]="timereportIdList" [bookingid]="-1" [notifyEmail]="notifyemail" [notifySms]="notifysms" [(visible)]="popup[0].Visible" [option]="popup[0].Option" (doneChange)="reloadAfterBook()"></swe-abscentdata>

<swe-card header="{{languageService.getItem(1473)}}" [(open)]="open" (openChange)="load()" (refreshChange)="load()" [hasRefresh]="true" icon="bi-bell-fill">
  <span swe-rightcommand></span>
  <swe-list [data]="subscriptions" [loading]="isloading" [header]="languageService.getItem(1085)" *ngIf="subscriptions&& subscriptions.length>0">
    <div class="list-group-item list-group-item-action d-flex flex-wrap" [ngClass]="{'list-group-item-secondary swe-disabled': isCurrent(subscription)}" *ngFor="let subscription of subscriptions">
      <div class="col-10 col-md-8">{{getName(subscription)}}</div>
      <div class="col-2 col-md-4"><i class="bi bi-swe-fw bi-trash-fill bi-swe-pull-right swe-click" *ngIf="!isCurrent(subscription)" (click)="delete(subscription)"></i></div>
    </div>
  </swe-list>
  <swe-list [data]="requestgroups" [header]="languageService.getItem(1456)" *ngIf="requestgroups && requestgroups.length > 0">
    <div class="list-group-item swe-click d-flex flex-wrap" [ngClass]="{'list-group-item-info': group.Subscribe}" *ngFor="let group of requestgroups" (click)="toggleSubscribeRequestgroup(group, $event)">
      <div class="flex-column" *ngIf="group.Access > 1 || group.Subscribe">
        <i class="bi bi-swe-fw ms-1 me-3" [ngClass]="{'bi-check-square': group.Subscribe, 'bi-square': !group.Subscribe}" [attr.title]="languageService.getItem(1450)"></i>
      </div>
      <div class="flex-column" [attr.title]="languageService.getItem(1454)+' '+group.Name">
        <span>{{group.Name}}</span>
      </div>
    </div>
  </swe-list>
</swe-card>
